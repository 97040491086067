<template>
<div class="blog-card" >
  <BlogCard v-for="post in posts" 
    :key="post.id"
    :id="post.id" 
    :title="post.title"
    :short="post.short"
    :image="post.image"
  />
  </div>
</template>


<script>
import BlogCard from "@/components/blog/BlogCard.vue"

export default {
  components: {
    BlogCard
  },
  props: {
    displayNum: Number
  },
  data() {
    return {
      posts: null
    }
  },
  created() {
    try {
      this.posts = require("@/assets/blog/blogs.json").blog
    } catch {
      console.log("Could not find blogs.json file")
    }

    if (this.displayNum !== undefined && this.displayNum > 0 && this.displayNum <= this.posts.length){
      this.posts = this.posts.slice(0, this.displayNum)
    }
  },
}
</script>


<style scoped lang="scss">
.blog-card {
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}
</style>
