<template>
    <div id="logo-river">
        <div class="animation-container" v-for="object in objects" :key="object.id">
            <div
                class="animation-horizontal" 
                :style="[
                    {'animation-duration': this.speed + 's'},
                    {'animation-delay': object.delay + 's'},
                ]"
            >
                <div 
                    :class="[
                        'logo-'+object.id, 
                        'animation-vertical'
                    ]" 
                    :style="[
                        {'animation-delay': object.delay + 's'},
                    ]"
                >
                    <img 
                        :src="logoSource" 
                        :alt="object.id" 
                        :style="{height: object.size + '%'}"
                    />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        logoSource: String
    },
    data() {
        return {
            pixelPerLogo: 20, // Every x pixels of the component's width there is a logo object
            speed: null, // Horizontal animation speed
            objects: new Array, // holds logo objects
            currId: 0, // id counter
        }
    },

    mounted() {
        window.addEventListener("resize", this.screenResizeHandler)
        this.screenResizeHandler()
    },
    methods: {
        screenResizeHandler() {
            try {
                let width = document.getElementById('logo-river').getBoundingClientRect().width
                this.setHoriztonalSpeed(width)
                this.modifyObjects(width)
            }
            catch (e) {
                console.log(e)
            }
        },
        setHoriztonalSpeed(width) {  
            // 70px = 1s (max 1000px)
            this.speed = (width / 70)

        },
        modifyObjects(width) {
            let count = Math.ceil(width / this.pixelPerLogo)

            // Remove logos if there are too much
            if (this.objects.length > count) {
                while (this.objects.length > count) {
                    this.objects.shift()
                }
            } 
            // Add logos if there are not enough
            else if (this.objects.length < count) {
                while (this.objects.length < count) {
                    this.objects.push(this.getRandomObject(this.currId))
                    this.currId = this.currId + 1
                }
            }
        },
        getRandomObject(id) {
            let object = {
                'id': id,
                'size': Math.floor(Math.random() * 50) + 50, // range 50 to 100
                'delay': Math.floor(Math.random() * this.speed) + Math.random() // range 0 to speed+1
            }

            return object
        }
    },
}
</script>


<style scoped lang="scss">

#logo-river {
    position: relative;
    height: 150px;
    margin: 1rem 0;
    overflow-x: hidden;
    text-align: start;
    user-select: none;

    .animation-horizontal {
        position: absolute;
        height: 100%;
        width: 100%;
        animation: horizontal ease-in-out;
        animation-iteration-count: infinite;
        overflow: hidden;
        opacity: 0;

        .animation-vertical {
            display: flex;
            height: 50%;
            text-align: center;
            animation: vertical ease-in-out;
            animation-iteration-count: infinite;
            animation-duration: 2s;
        }
    }
}

@keyframes horizontal {
    from {
        transform: translateX(-25%);
        opacity: 1;
    }

    to {
        transform: translateX(125%);
        opacity: 1;
    }
}

@keyframes vertical {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0%);
    }
}
</style>