<template>
    <div class ="blog-post-block-image">
        <img :src="imgSource" :alt="imgSource" loading="lazy" class="img-fluid">
    </div>
</template>


<script>
export default {
    props: ["id", "image"],
    data() {
        return {
            imgSource: null
        }
    },
    created() {
        try {
            this.imgSource = require(`@/assets/blog/${this.id}/img/${this.image}`)
        } catch {
            console.log(`Can't find '${this.image}' image.`)
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/scss/variables.scss";

.blog-post-block-image {
    padding: 1rem;
    img {
        box-shadow: $image_shadow;
    }
}
</style>
