<template>
    <div id="router-navbar">
        <nav class="navbar">
            <router-link to="/">Home</router-link>
            <router-link to="/blog/posts">Blogs</router-link>
            <router-link to="/about">About</router-link>
        </nav>
    </div>
</template>


<style lang="scss">
@import "@/scss/variables.scss";

#router-navbar {
  padding: 2em;
  background-color: $primary;
}

#router-navbar a {
  font-weight: bold;
  color: $font;
}

#router-navbar a.router-link-exact-active {
  color: $background;
}
</style>
