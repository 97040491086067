<template>
  <div id="home-view">
    <div class="home-title">
      <img src="icon.svg"/>
      <div class="fs-1 fw-bold">Dubu Kang</div>
      <div class="fs-6 fw-light"> Written in Vue 3 with Bootstrap v5</div>
    </div>
    <div class="home-section">
      <div class="fs-4 text-start fw-bold">What is Dubu Kang?</div>
      <div class="fs-6 text-start">Dubu Kang means "Tofu River" in Korean. It's also the place where I post blogs!</div>
      <div class="fs-6 text-start fw-light">Learn more about its meaning in the about page.</div>
    </div>
    <div class="home-section">
      <div class="fs-4 text-start fw-bold">Other</div>
      <div class="fs-6 text-start">Find me on GitHub <a href="https://www.github.com/mrgravey">@MrGravey</a></div>
    </div>
    <LogoRiver :logoSource='"icon.svg"'/>
    <div class="home-latest-blogs">
      <div class="fs-2 fw-bold latest-blogs-title">
        Latest Posts
      </div>
      <BlogList :displayNum='3'/>
    </div>
  </div>
</template>


<script>
import BlogList from "@/components/blog/BlogList.vue"
import LogoRiver from "@/components/LogoRiver.vue"

export default {
  components: {
    BlogList,
    LogoRiver,
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/variables.scss";

#home-view {
  margin: 2rem auto;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;

  .home-title {
    padding: 1rem 0 3rem 0;
    background-color: $background;
  }

  .home-section {
    padding: 0 2rem 2rem 2rem;
    background-color: $background;
  }

  .home-latest-blogs {
    margin-top: 2rem;

    .latest-blogs-title {
      margin-bottom: 1rem;
    }
  }
}
</style>
