<template>
  <div id="blog-view">
    <router-view/>
  </div>
</template>


<style lang="scss">
#blog-view {
  margin: 2rem auto;
  width: 100%;
  max-width: 1000px;
}
</style>
