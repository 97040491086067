<template>
    <div class ="blog-post-block-text">
        <p class="text-start"> {{ text }} </p>
    </div>
</template>


<script>
export default {
    props: ["text"]
}
</script>


<style scoped lang="scss">
.blog-post-block-text {
    padding: 1rem;

    .text-start {
        margin: 0;
        white-space: pre-wrap;
    }
}
</style>
