import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Blog from '@/views/Blog.vue'
import BlogList from '@/components/blog/BlogList.vue'
import BlogPost from '@/components/blog/BlogPost.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/blog',
    redirect: {name: 'BlogList'},
    name: 'Blog',
    component: Blog,
    children: [
      {
        path: 'posts',
        name: 'BlogList',
        component: BlogList
      },
      {
        path: ':id',
        component: BlogPost,
        props: true
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NoPage',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
