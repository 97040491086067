<template>
    <div class="card">
        <img :src="imgSource" class="card-img-top" alt="...">
        <div class="card-body d-flex-row">
            <div class="card-title fs-2 fw-bold">{{ title }}</div>
            <div class="card-text fs-6 fw-light">{{ short }}</div>
            <router-link :to="'/blog/'+id" class="btn btn-primary">Read</router-link>
        </div>
    </div>
</template>


<script>
export default {
    props: ["id", "title", "short", "image"],
    data() {
        return {
            imgSource: null
        }
    },
    created() {
        try {
            this.imgSource = require(`@/assets/blog/${this.id}/img/${this.image}`)
        } catch {
            console.log(`Can't find '${this.image}' image.`)
        }
    },
}
</script>


<style scoped lang="scss">
@import "@/scss/variables.scss";

.card {
    width: 18rem;
    height: 25rem;
    margin: 1rem;
    border: none;
    text-align: center;
    background-color: $background;
    box-shadow: $background_shadow;

    .card-img-top {
        height: 50%;
    }

    .card-body {
        height: 50%;
        padding: 0;

        .card-text {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical; 
            overflow: hidden;
        }

        .btn {
            width: 50%;
            margin: 0;
            margin: 1rem 0;
        }
    }

}
</style>
