<template>
  <div class="blog-post">
    <div class="blog-post-header">
      <div class="fs-1 fw-bold">{{metadata.title}}</div>
      <div class="fs-6 fw-light">{{metadata.short}}</div>
    </div>
    <div class="blog-post-block" v-for="block in blocks" :key="block.index">
        <BlockText v-if="block.type === 0" :text="block.data"/>
        <BlockImage v-else-if="block.type === 1" :id="id" :image="block.data"/>
    </div>
  </div>
</template>


<script>
import BlockText from "@/components/blog/block/BlogPostBlockText.vue"
import BlockImage from "@/components/blog/block/BlogPostBlockImage.vue"

export default {
  props: ["id"],
  components: {
    BlockText,
    BlockImage
  },
  data() {
    return {
      metadata: null,
      blocks: null
    }
  },
  created() {
    try {
      var post = require(`@/assets/blog/${this.id}/post.json`)
      this.metadata = post.metadata
      this.blocks = post.block
    } catch {
      this.$router.push({name: 'Blog'})
    }
  },
  beforeMount() {
    window.scrollTo(0,0)
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/variables.scss";

.blog-post {
  display: flex;
  flex-direction: column;
  background-color: $background;
  padding: 2rem 0;

  .blog-post-header {
    margin-bottom: 1rem;
    text-align: center;
  }

  .blog-post-block {
    margin: 1rem;
    text-align: left;
  }
}
</style>
