<template>
    <div id="footer">
        Copyright &copy; 2021 - {{ year }}
    </div>
</template>


<script>
export default {
    data() {
        return {
            year: new Date().getFullYear()
        }
    }
}
</script>


<style lang="scss">
#footer {
    margin-top: auto;
    margin-bottom: 1rem;
}
</style>
