<template>
  <div id="app-page">
    <RouterNavBar/>
    <router-view/>
    <Footer/>
  </div>
</template>


<script>
import RouterNavBar from '@/components/RouterNavBar.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    RouterNavBar,
    Footer
  },
}
</script>


<style lang="scss">
@import "@/scss/variables.scss";

#app {
  font-family: "OpenSans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font;
  background: linear-gradient(to bottom, $background_gradient1, $background_gradient2);
  background-attachment: fixed;
}

#app-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
